import PropTypes from 'prop-types'
import React from 'react'
import styles from './assets/CardHeader.module.scss'

const CardHeader = ({ children }) => {
  return (
    <div className={styles.card__header}>
      {children}
    </div>
  )
}

CardHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default CardHeader
