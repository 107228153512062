import PropTypes from 'prop-types'
import React from 'react'
import styles from './assets/MenuItem.module.scss'

const MenuItem = ({ children, className, disabled, onClick, ...props }) => {
  return (
    <li
      {...props}
      onClick={(event) => {
        if (disabled) {
          event.stopPropagation()
        } else if (onClick) {
          onClick(event)
        }
      }}
      className={[
        className,
        styles.menu__item,
        disabled ? styles['menu__item--disabled'] : ''
      ].join(' ')}
    >
      {children}
    </li>
  )
}

MenuItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

export default MenuItem
