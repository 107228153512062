import PropTypes from 'prop-types'
import React from 'react'
import styles from './assets/Drawer.module.scss'

const Drawer = ({
  children,
  className,
  show,
  reverse,
  onClose
}) => {
  /**
   * Drawer closing mechanism
   */
  function closeDrawer() {

    if (onClose) {
      onClose()
    }
  }

  return (
    <div className={[className, styles.drawer__container].join(' ')}>
      {show &&
        <div
          className={styles.drawer__overlay}
          onClick={closeDrawer}>
        </div>}
      <div className={[
        styles.drawer__body,
        show ? styles['drawer__body--active'] : '',
        reverse ? styles['drawer__body--reverse'] : ''
      ].join(' ')}>
        {children}
      </div>
    </div>
  )
}

/* --------------- props --------------- */
Drawer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  reverse: PropTypes.bool,
}

Drawer.defaultProps = {
  className: '',
  reverse: false,
}

export default Drawer
