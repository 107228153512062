import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import styles from './assets/Button.module.scss'
import { LoadingIcon } from './assets/images'

const Button = ({
  children,
  inverted,
  full,
  disabled,
  loading,
  className,
  onClick,
  ripple,
  ...props
}) => {
  // initialize
  const buttonRef = useRef()

  function createRipple(e) {
    // definitions
    const button = buttonRef.current
    const circle = document.createElement('span')
    const { top, left } = button.getBoundingClientRect()
    const d = Math.max(button.clientWidth, button.clientHeight)

    // create ripple attributes
    button.textContent = ''
    button.appendChild(circle)
    circle.style.width = circle.style.height = `${d}px`
    circle.style.left = `${e.clientX - left - d / 2}px`
    circle.style.top = `${e.clientY - top - d / 2}px`
    circle.classList.add(styles.button__ripple)
  }

  return (
    <div className={[
      className,
      styles.button__container,
      full ? styles['button__container--full'] : ''
    ].join(' ')}
    >
      <button
        {...props}
        onClick={(e) => {
          if (disabled || loading) {
            e.preventDefault()
          } else {
            if (ripple) {
              createRipple(e)
            }
            if (onClick) {
              onClick(e)
            }
          }
        }}
        className={[
          styles.button,
          inverted ? styles['button--inverted'] : '',
          (disabled || loading) ? styles['button--disabled'] : ''
        ].join(' ')}
      >
        {children}
        {!loading ? null
          : <LoadingIcon className={styles.button__loader} />}
        <div
          ref={buttonRef}
          className={styles.button__effects}
        />
      </button>
    </div>
  )
}

/* --------------- props --------------- */
Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  inverted: PropTypes.bool,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  ripple: PropTypes.bool
}

Button.defaultProps = {
  className: '',
  ripple: true
}

export default Button
