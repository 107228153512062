import PropTypes from 'prop-types'
import React from 'react'
import styles from './assets/Card.module.scss'

const Card = ({
  children,
  className,
  ...props
}) => (
  <div
    {...props}
    className={[className, styles.card__container].join(' ')}
  >
    {children}
  </div>
)

/* --------------- props --------------- */
Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Card
