import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Floatable from '../Floatable/Floatable'
import useFloat from '../Floatable/hooks/useFloat'
import styles from './assets/DatePicker.module.scss'
import { ArrowLeftIcon, ArrowRightIcon, CloseIcon, DateIcon } from './assets/images'

const DatePicker = ({
  label,
  placeholder,
  className,
  value,
  format,
  onChange,
  required,
  right,
  minDate,
  maxDate,
  error,
  errorMessage,
  readOnly,
  clearable
}) => {
  // ref
  const input = useRef()
  const icon = useRef()

  const {
    toggleActive: toggleDatePicker,
    closeOverlay: closeDatePicker,
    updateBody,
    ...floatConfigs
  } = useFloat(null, false, true, right || false)
  const { active: focus, handleRef } = floatConfigs

  // states
  const [internalValue, setInternalValue] = useState()
  const [tempValue, setTempValue] = useState(new Date())

  // lookups
  const days = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ]

  // -------------- value updates -------------- //

  /**
   * Update the selected date
   * @param {number} year  the selected year
   * @param {number} month the selected month
   * @param {number} day the selected day
   */
  function updateValue(year, month, day) {
    const newValue = new Date(year, month, day)
    setInternalValue(newValue)
    emitChange(newValue)
  }

  /**
   * Emit the new changed value
   * @param {*} emittedValue the new updated value
   */
  function emitChange(emittedValue) {
    if (onChange) {
      if (format) {
        onChange(formatDate(emittedValue, format))
      } else {
        onChange(emittedValue)
      }
    }
  }

  /**
   * Update active month
   * @param {number} step +1 or -1
   */
  function updateMonth(step) {
    setTempValue(new Date(tempValue.setMonth(tempValue.getMonth() + step)))
  }

  // ------------------ values ----------------- //

  /**
   * Get the value to be displayed
   */
  const getSelectedDate = useCallback(function () {
    return internalValue ? formatDate(internalValue, 'dd/MM/YYYY') : ''
  }, [internalValue])

  /**
   * Process date before reading
   * @param {string|Date} dateInput the date to be processed
   */
  function getDateValue(dateInput) {
    if (format) {
      return parseDate(dateInput, format)
    } else if (typeof dateInput === 'string') {
      return null
    } else {
      return dateInput
    }
  }

  /**
   * Get the values of the date
   */
  const getValue = useCallback(function () {
    // initialize
    const months = [
      'January', 'February', 'March', 'April',
      'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'
    ]
    const date = tempValue
    const today = new Date()
    const startClone = new Date(date.getFullYear(), date.getMonth(), 1)
    const daysClone = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const parsedMin = getDateValue(minDate)
    const parsedMax = getDateValue(maxDate)

    return {
      year: date.getFullYear(),
      month: date.getMonth(),
      monthName: months[date.getMonth()],
      monthDays: daysClone.getDate(),
      start: startClone.getDay(),
      today: {
        year: today.getFullYear(),
        month: today.getMonth(),
        day: today.getDate()
      },
      current: {
        year: internalValue?.getFullYear(),
        month: internalValue?.getMonth(),
        day: internalValue?.getDate()
      },
      min: {
        year: parsedMin?.getFullYear(),
        month: parsedMin?.getMonth(),
        day: parsedMin?.getDate()
      },
      max: {
        year: parsedMax?.getFullYear() || 3000,
        month: parsedMax?.getMonth() || 13,
        day: parsedMax?.getDate() || 32
      }
    }
  }, [internalValue, tempValue, minDate, maxDate])

  /**
   * Update component internal states
   */
  const renderValues = useCallback(function () {
    // initialize
    const newValue = getDateValue(value)
    const parsedMin = getDateValue(minDate)
    const parsedMax = getDateValue(maxDate)

    if (newValue) {
      // validate date range
      if (parsedMax && newValue.getTime() > parsedMax.getTime()) {
        emitChange(parsedMax)
        setInternalValue(new Date(parsedMax.getTime()))
        setTempValue(new Date(parsedMax.getTime()))
      } else if (parsedMin && newValue.getTime() < parsedMin.getTime()) {
        emitChange(parsedMin)
        setInternalValue(new Date(parsedMin.getTime()))
        setTempValue(new Date(parsedMin.getTime()))
      } else {
        setInternalValue(new Date(newValue.getTime()))
        setTempValue(new Date(newValue.getTime()))
      }
    } else {
      // case not data found
      setInternalValue(null)
      if (parsedMin) {
        // handle focus when min date is set
        setTempValue(new Date(parsedMin.getTime()))
      } else {
        // set the default focus to current month
        setTempValue(new Date())
      }
    }
  }, [maxDate, minDate, value])

  // ------------------ parser ----------------- //

  /**
   * Format date to given pattern
   * @param {Date} date date to be formatted
   * @param {string} format the target format
   */
  function formatDate(date, format) {
    const pad = (str) => (str + '').padStart(2, '0')
    format = format.replace('dd', pad(date.getDate()))
    format = format.replace('MM', pad(date.getMonth() + 1))
    format = format.replace('YYYY', date.getFullYear())
    return format
  }

  /**
   * Parse the string date
   * @param {string} date
   * @param {string} format the format of input string
   */
  function parseDate(date, format) {
    // short circuit check
    if (!date) {
      return null
    }
    if (date instanceof Date) {
      return date
    }

    // initialize
    let day, month, year
    const dayIndex = format.indexOf('dd')
    const monthIndex = format.indexOf('MM')
    const yearIndex = format.indexOf('YYYY')

    // parse input
    if (dayIndex !== -1) {
      day = parseInt(date.substr(dayIndex, 2))
    }
    if (monthIndex !== -1) {
      month = parseInt(date.substr(monthIndex, 2))
    }
    if (yearIndex !== -1) {
      year = parseInt(date.substr(yearIndex, 4))
    }

    // assemble date
    const result = new Date(year, month - 1, day)
    if (isNaN(result.getTime())) {
      return null
    }
    return result
  }

  // --------------- auto effects -------------- //
  useEffect(renderValues, [renderValues, focus])

  const { year, month, monthName, monthDays, start, today, current, min, max } = getValue()

  return (
    <div className={[className, styles.datepicker__container].join(' ')}>

      {/* Input */}
      {label &&
        <label className={styles.datepicker__label}>
          {label} {required && <span> * </span>}
        </label>}
      <div className={styles.datepicker__inputwrapper} ref={handleRef}>

        {/* Icons */}
        {(clearable && getSelectedDate() !== '')
          ? <CloseIcon className={styles.datepicker__close} onClick={() => emitChange()} />
          : <span ref={icon} className={styles.datepicker__icon}>
            <DateIcon onClick={toggleDatePicker} />
            {/* eslint-disable-next-line react/jsx-closing-tag-location */}
          </span>}

        {/* Input */}
        <input
          ref={input}
          readOnly
          value={getSelectedDate()}
          onClick={() => {
            if (!readOnly) {
              if (!focus) {
                updateBody(toggleDatePicker)
              } else {
                closeDatePicker()
              }
            }
          }}
          placeholder={placeholder}
          className={[
            styles.datepicker__element,
            error ? styles['datepicker__element--error'] : '',
            focus ? styles['datepicker__element--active'] : '',
            readOnly ? styles['datepicker__element--readonly'] : ''
          ].join(' ')}
        />

      </div>

      {/* Calender View */}
      <Floatable
        {...floatConfigs}
        className={[
          styles.datepicker__calendar,
          error ? styles['datepicker__calendar--error'] : ''
        ].join(' ')}
      >

        {/* Top Controls */}
        <div className={styles.datepicker__month}>
          <ArrowLeftIcon
            className={(min.year > year || (min.year === year && min.month >= month))
              ? styles.datepicker__disabled : ''}
            onClick={() => updateMonth(-1)}
          />
          <div className={styles.datepicker__activemonth}>
            {monthName} {year}
          </div>
          <ArrowRightIcon
            className={(max.year < year || (max.year === year && max.month <= month))
              ? styles.datepicker__disabled : ''}
            onClick={() => updateMonth(1)}
          />
        </div>

        <div className={styles.datepicker__details}>
          <div className={styles.datepicker__days}>
            {days.map((day, index) => (
              <span className={styles.datepicker__dayweek} key={index}>
                {day}
              </span>
            ))}
          </div>

          <div className={styles.datepicker__numbers}>
            {Array(start).fill().map((s, i) => <div key={i} />)}
            {Array(monthDays).fill().map((d, i) => (
              <div key={i}>
                <span
                  onClick={() => updateValue(year, month, i + 1)}
                  className={[
                    // check if day is selected day
                    (current.year === year &&
                      current.month === month &&
                      current.day === (i + 1)
                    ) ? styles.datepicker__selected : '',

                    // check if day is today
                    (today.year === year &&
                      today.month === month &&
                      today.day === (i + 1)
                    ) ? styles.datepicker__today : '',

                    // check if day is range
                    (
                      (min.year > year ||
                        (min.year === year && min.month > month) ||
                        (min.year === year && min.month === month && min.day > (i + 1))) ||
                      (max.year < year ||
                        (max.year === year && max.month < month) ||
                        (max.year === year && max.month === month && max.day < (i + 1))
                      )
                    ) ? styles.datepicker__disableditem : ''
                  ].join(' ')}
                >
                  <span> {i + 1} </span>
                </span>
              </div>
            ))}
          </div>

        </div>
        {/* </div> */}
      </Floatable>

      {/* Validation Message */}
      <span
        className={[
          styles.datepicker__error,
          error ? styles['datepicker__error--active'] : ''
        ].join(' ')}
      >
        {errorMessage}
      </span>

    </div>
  )
}

/* --------------- props --------------- */
DatePicker.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  format: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  errorMessage: PropTypes.string,
  right: PropTypes.bool,
  readOnly: PropTypes.bool,
  clearable: PropTypes.bool
}

DatePicker.defaultProps = {
  className: '',
  placeholder: 'Select date',
  required: false,
  error: false,
  readOnly: false,
  clearable: false
}

export default DatePicker
