import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import Floatable from '../Floatable/Floatable'
import useFloat from '../Floatable/hooks/useFloat'
import styles from './assets/Menu.module.scss'
import MenuBody from './components/MenuBody/MenuBody'
import MenuHandle from './components/MenuHandle/MenuHandle'

const Menu = ({
  children,
  sticky,
  className,
  overflowed,
  bodyClassName,
  onOpen,
  onClose
}) => {
  // states
  const {
    toggleActive,
    closeOverlay: closeMenu,
    updateBody,
    ...floatConfigs
  } = useFloat(onClose, overflowed, sticky)
  const { active, handleRef, overrideHeightRef } = floatConfigs

  useEffect(() => {
    // on open event
    if (active && onOpen) {
      setTimeout(onOpen, 0)
    }
  }, [active])

  // parse sub-components
  const handle = children.find(({ type }) => type.name === MenuHandle.name)
  const body = children.find(({ type }) => type.name === MenuBody.name)
  const { props: { children: menuItems } } = body

  return (
    <div className={styles.menu}>
      <div className={[styles.menu__container, className].join(' ')}>
        <div
          ref={handleRef}
          className={styles.menu__handle}
          onClick={() => {
            if (!active) {
              updateBody(toggleActive)
            } else {
              closeMenu()
            }
          }}
        >
          {handle}
        </div>

        {/* Dropdown body */}
        <Floatable
          {...floatConfigs}
          floating
          ref={overrideHeightRef}
          overflowed={overflowed}
          className={bodyClassName}
        >
          <ul className={styles.menu__list}>
            {menuItems}
          </ul>
        </Floatable>

      </div>
    </div>
  )
}

/* --------------- props --------------- */
Menu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  sticky: PropTypes.bool,
  overflowed: PropTypes.bool,
  bodyClassName: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
}

Menu.defaultProps = {
  className: '',
  sticky: false,
  bodyClassName: ''
}

export default Menu
