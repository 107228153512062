import PropTypes from 'prop-types'
import React, { forwardRef, memo, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styles from './assets/Floatable.module.scss'

const Floatable = forwardRef(({
  children,
  active,
  domElement,
  configs,
  savedCallback,
  setSavedCallback,
  containerRef,
  setContainerRef,
  contentRef,
  overflowed,
  className,
  vertical,
  floating
}, ref) => {
  // --------------- auto effects -------------- //

  useEffect(() => {
    if (savedCallback) {
      savedCallback()
    }
    setSavedCallback()
  }, [configs])

  useEffect(() => { if (ref) setContainerRef(ref) }, [])

  const { isTop, isRight, style } = configs

  const innerContent = () => (
    <div
      className={[
        styles.floatable__wrapper,
        isTop ? styles['floatable__wrapper--topped'] : '',
        vertical ? styles['floatable__wrapper--vertical'] : '',
        floating ? styles['floatable__wrapper--floating'] : ''
      ].join(' ')}
    >
      <div
        ref={containerRef}
        className={[
          className,
          styles.floatable__body,
          active ? styles['floatable__body--active'] : '',
          isTop ? styles['floatable__body--topped'] : '',
          isRight ? styles['floatable__body--righted'] : '',
          overflowed ? styles['floatable__body--overflow'] : ''
        ].filter(x => x).join(' ')}
        style={style}
      >
        <div ref={contentRef}>
          {children}
        </div>
      </div>
    </div>
  )

  // render based on
  if (overflowed) {
    if (domElement) {
      return ReactDOM.createPortal(innerContent(), domElement)
    } else {
      return null
    }
  } else {
    return innerContent()
  }
})

/* --------------- props --------------- */
Floatable.propTypes = {
  // refs
  containerRef: PropTypes.shape({
    current: PropTypes.any
  }),
  contentRef: PropTypes.shape({
    current: PropTypes.any
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,

  // states
  active: PropTypes.bool,
  domElement: PropTypes.object,
  configs: PropTypes.object,
  savedCallback: PropTypes.func,
  setSavedCallback: PropTypes.func,

  // options
  overflowed: PropTypes.bool,
  vertical: PropTypes.bool,
  className: PropTypes.string
}

export default memo(Floatable)
