import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Floatable from '../Floatable/Floatable'
import useFloat from '../Floatable/hooks/useFloat'
import Scrollbar from '../Scrollbar/Scrollbar'
import { ArrowDownIcon } from './assets/images'
import styles from './assets/Select.module.scss'
import Option from './components/Option/Option'

const Select = ({
  label,
  placeholder,
  value,
  onChange,
  children,
  className,
  bodyClassName,
  readOnly,
  required,
  error,
  errorMessage,
  overflowed,
  noDefault,
  defaultOptionText,
  handleStyle,
  onOpen,
  onClose,
  ...props
}) => {
  // states
  const {
    toggleActive,
    closeOverlay: closeSelect,
    updateBody,
    ...floatConfigs
  } = useFloat(onClose, overflowed)
  const { active, handleRef, overrideWidthRef } = floatConfigs

  // states
  const [enable, setEnable] = useState(false)
  const [options, setOptions] = useState([])
  const [message, setMessage] = useState('')

  /**
   * Show selected value
   */
  function getValue() {
    if (value) {
      const val = options.map((x) => ({
        value: x.props.value,
        content: x.props.children
      })).find(x => x.value === value || x.content === value)
      return val?.content || placeholder
    } else {
      return placeholder
    }
  }

  /**
   * Create the body far away
   * @param {boolean} action flag for callbacks
   */
  function dispatchDropdown(action) {
    // on open event
    if (action && active && onOpen) {
      setTimeout(onOpen, 0)
    }
  }

  /**
   * Update the select component value
   * @param {any} newValue new selected value
   */
  function selectAndClose(newValue) {
    if (onChange) {
      onChange(newValue)
    }
    closeSelect()
  }

  // --------------- auto effects -------------- //

  // update the dropdown body and callbacks
  useEffect(() => dispatchDropdown(true), [active])
  useEffect(() => dispatchDropdown(false), [value])

  // parse sub-components
  useEffect(() => {
    const subComponents = Array.isArray(children) ? children : [children]
    setEnable(
      subComponents
        .map(x => typeof x === 'object' && !x?.props?.hidden)
        .filter(x => x).length > 0
    )
    const optionsParsed = subComponents.filter((element) => element?.type?.name === Option.name)
    setOptions(optionsParsed)
  }, [children])

  // error message
  useEffect(() => {
    // extract active message
    if (typeof errorMessage === 'object' && errorMessage !== null) {
      const [firstMessage] = Object
        .entries(errorMessage)
        .map(([key, value]) => value && key)
        .filter(x => x)
      setMessage(firstMessage)
    } else {
      setMessage(errorMessage)
    }
  }, [errorMessage])

  return (
    <div
      {...props}
      className={[
        className,
        styles.select__container,
        overflowed ? styles['select__container--inline'] : ''
      ].join(' ')}
    >

      <label className={styles.select__label}>
        {label} {required && <span> * </span>}
      </label>

      {/* Dropdown handle */}
      <div
        style={handleStyle}
        ref={handleRef}
        className={[
          styles.select__element,
          active ? styles['select__element--active'] : '',
          error ? styles['select__element--error'] : '',
          readOnly ? styles['select__element--readonly'] : ''
        ].join(' ')}
        onClick={() => {
          if (!readOnly && enable) {
            if (!active) {
              updateBody(toggleActive)
            } else {
              closeSelect()
            }
          }
        }}
      >
        <span className={!value ? styles.select__place : ''}> {getValue()} </span>
        <ArrowDownIcon className={[
          styles.select__arrow,
          active ? styles['select__arrow--active'] : ''
        ].join(' ')}
        />
      </div>

      <Floatable
        {...floatConfigs}
        vertical
        overflowed={overflowed}
        className={[
          bodyClassName,
          overflowed ? styles['select__body--overflowed'] : '',
          error ? styles['select__body--error'] : ''
        ].join(' ')}
      >

        {/* Dropdown body */}
        <Scrollbar disableXScroll height={160}>
          <ul className={overflowed ? styles.select__block : ''} ref={overrideWidthRef}>

            {!noDefault &&
              <Option value={null} onClick={selectAndClose}>
                {defaultOptionText}
              </Option>}
            {
              options.map((component, index) => {
                const {
                  selected: optionSelected,
                  value: optionValue,
                  children: content,
                  onClick,
                  ...args
                } = component.props

                // selection handler
                let selected
                if (value) {
                  if (optionValue) {
                    selected = (value === optionValue)
                  } else if (content) {
                    selected = (value === content)
                  }
                } else {
                  selected = optionSelected
                }

                // click handler
                const handleClick = () => {
                  selectAndClose(optionValue || content)
                  if (onClick) {
                    onClick()
                  }
                }
                return (
                  <component.type
                    {...args}
                    key={index}
                    children={content}
                    selected={selected}
                    onClick={handleClick}
                  />
                )
              })
            }
          </ul>
        </Scrollbar>

      </Floatable>

      {/* Validation Message */}
      <span
        className={[
          styles.select__error,
          error ? styles['select__error--active'] : ''
        ].join(' ')}
      >
        {message}
      </span>

    </div>
  )
}

/* --------------- props --------------- */
Select.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  handleStyle: PropTypes.object,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  noDefault: PropTypes.bool,
  overflowed: PropTypes.bool,
  defaultOptionText: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
}

Select.defaultProps = {
  className: '',
  error: false,
  required: false,
  errorMessage: '',
  noDefault: false,
  overflowed: false,
  defaultOptionText: 'please select ...',
  placeholder: 'Please select ...',
  children: []
}

export default Select
