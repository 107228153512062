import PropTypes from 'prop-types'
import React from 'react'
import Button from '../../../Button/Button'
import styles from './assets/CardActions.module.scss'

const CardActions = ({ actions }) => {
  return (
    <div className={styles.card__actions}>
      {actions.map(({ label, inverted, onClick }, index) =>
        <Button
          inverted={inverted}
          key={index}
          onClick={onClick}
        >
          {label}
        </Button>
      )}
    </div>
  )
}

CardActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  )
}

CardActions.defaultProps = {
  actions: []
}

export default CardActions
