import { useCallback, useEffect, useState } from 'react'
import { MODE } from '../utils/constants'

export default function useScroll(wrapper, content, mode) {
  // states
  const [tempPosition, setTempPosition] = useState()
  const [inPosition, setInPosition] = useState()

  // ----------------- updates ----------------- //

  /**
   * Adjust the position of handle
   */
  const adjustBarPosition = useCallback(() => {
    let scrollConfigs
    if (mode === MODE.HORIZONTAL) {
      // horizontal scroll

      // variables
      const wrapperWidth = wrapper?.clientWidth
      const contentWidth = content?.clientWidth

      // calculations
      scrollConfigs = {
        barOffset: (wrapper?.scrollLeft / contentWidth) * 100,
        barSize: (wrapperWidth / contentWidth) * 100,
        showHandle: contentWidth > wrapperWidth
      }
    } else {
      // vertical scroll

      // variables
      const wrapperHeight = wrapper?.clientHeight
      const contentHeight = content?.clientHeight

      // calculations
      scrollConfigs = {
        barOffset: (wrapper?.scrollTop / contentHeight) * 100,
        barSize: (wrapperHeight / contentHeight) * 100,
        showHandle: contentHeight > wrapperHeight
      }
    }

    // return updated scroll settings
    return scrollConfigs
  })

  // -------------- bar callbacks -------------- //

  /**
   * De-register events
   */
  function upFunction() {
    document.removeEventListener('mouseup', upFunction)
    document.removeEventListener('mousemove', moveFunction)
  }

  /**
   * Handle the dragging event
   * @param event the mouse positions
   */
  function moveFunction(event) {
    setInPosition(mode === MODE.HORIZONTAL ? event.clientX : event.clientY)
  }

  // --------------- auto effects -------------- //

  useEffect(() => {
    if (inPosition && inPosition !== tempPosition) {
      setTempPosition(inPosition)
      const delta = inPosition - tempPosition

      if (mode === MODE.HORIZONTAL) {
        // horizontal scroll
        const scroll = (delta / wrapper?.clientWidth) * content?.clientWidth
        if (wrapper) {
          wrapper.scrollLeft = wrapper.scrollLeft + scroll
        }
      } else {
        // vertical scroll
        const scroll = (delta / wrapper?.clientHeight) * content?.clientHeight
        if (wrapper) {
          wrapper.scrollTop = wrapper.scrollTop + scroll
        }
      }
    }
  }, [inPosition, tempPosition])

  // return logic
  return {
    adjustBarPosition,
    upFunction,
    moveFunction
  }
}
