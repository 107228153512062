import PropTypes from 'prop-types'
import React from 'react'
import styles from './assets/Option.module.scss'

const Option = ({
  children,
  className,
  selected,
  disabled,
  hidden,
  onClick,
  ...props
}) => (
  <li
    {...props}
    style={{ display: hidden ? 'none' : '' }}
    onClick={(event) => {
      if (disabled) {
        event.stopPropagation()
      } else if (onClick) {
        onClick(event)
      }
    }}
    className={[
      className,
      styles.option,
      selected ? styles['option--selected'] : '',
      disabled ? styles['option--disabled'] : ''
    ].join(' ')}
  >
    {children}
  </li>
)

Option.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  hidden: PropTypes.bool,
  onClick: PropTypes.func
}

export default Option
