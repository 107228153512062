import PropTypes from 'prop-types'
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import Checkbox from '../Checkbox/Checkbox'
import Floatable from '../Floatable/Floatable'
import useFloat from '../Floatable/hooks/useFloat'
import Scrollbar from '../Scrollbar/Scrollbar'
import useCommons from '../utils/useCommons'
import { ArrowIcon, LoadingIcon } from './assets/images'
import styles from './assets/SearchBox.module.scss'
import { HTTP } from './utils/constants'

const SearchBox = ({
  label,
  placeholder,
  placeholderSearch,
  noResultsText,
  className,
  value,
  selectSingle,
  searchList,
  onChange,
  fullObject,
  apiConfig,
  minSearchChars,
  valueAttribute,
  labelAttribute,
  error,
  errorMessage
}) => {
  // ref
  const container = useRef()
  const input = useRef()

  // states
  const [keyword, setKeyword] = useState('')
  const [list, setList] = useState([])
  const [checklist, setChecklist] = useState()
  const [loading, setLoading] = useState(false)

  // props
  const {
    searchApi, searchTerm, defaultTerm, type,
    headers, responsePayload, payloadParams, params
  } = apiConfig
  const { readValue } = useCommons()

  // ----------------- actions ----------------- //
  const {
    toggleActive: toggleBox,
    updateBody,
    ...floatConfigs
  } = useFloat(clearBox, false, true)
  const { active: focus, handleRef } = floatConfigs

  /**
   * Clear the box after close
   */
  function clearBox() {
    setKeyword('')
  }

  // ----------------- checkbox ---------------- //

  /**
   * Toggle all the list checks
   * @param {boolean} value the new value of master
   */
  function masterCheck(value) {
    // determine target value
    let targetValue
    if (value) {
      targetValue = list.map(item => val(item, valueAttribute))
        .reduce((o, key) => ({ ...o, [key]: true }), {})
    } else {
      targetValue = {}
    }

    emitChange(targetValue)
  }

  /**
   * Check / uncheck option
   * @param {string|number} key the selected key
   * @param {boolean} value the new value
   */
  function handleCheck(item, value) {
    // fetch value
    const key = val(item, valueAttribute)
    let targetValue

    if (selectSingle) {
      emitChange({ [key]: value });
      toggleBox()
      return;
    }

    if (value) {
      targetValue = {
        ...checklist,
        [key]: value
      }
    } else {
      const { [key]: removed, ...remainingChecks } = checklist
      targetValue = remainingChecks
    }

    emitChange(targetValue)
  }

  /**
   * Emit the new value
   * @param {object} targetValue
   */
  function emitChange(targetValue) {
    setChecklist(targetValue)
    let updatedValues = Object.keys(targetValue)

    // emit new values
    if (onChange) {
      if (fullObject) {
        updatedValues = list.filter(x => updatedValues.includes(val(x, valueAttribute)))
      }
      onChange(updatedValues)
    }
  }

  // ------------------ values ----------------- //

  /**
   * Get the component value selection
   */
  const getValue = useCallback(function () {
    const selectedKeys = Object.keys(checklist || {});
    return list
      .filter(x => selectedKeys.includes(val(x, valueAttribute) + ''))
      .map(x => val(x, labelAttribute));
  }, [checklist])

  /**
   * Get the checkbox value of element
   * @param {any} element the target element
   */
  function checkBoxValue(element) {
    return checklist ? checklist[val(element, valueAttribute)] : false
  }

  /**
   * Get key / value values
   * @param {object | string} element list element
   * @param {string} key the target attribute
   */
  function val(element, key) {
    if (typeof element === 'object' && element !== null) {
      return element[key] || element
    } else {
      return element
    }
  }

  /**
   * Get the list filtered by keyword
   */
  function getFilteredList() {
    // min search charcter should stop filtering
    // at certain character limit [offline / online]
    if (keyword.length < minSearchChars) {
      return list
    } else {
      return list.filter((element) => {
        const search = keyword.toUpperCase()
        return (String(val(element, labelAttribute)).toUpperCase().indexOf(search) > -1)
      })
    }
  }

  // --------------- integration --------------- //
  /**
   * Update options from API
   */
  async function getOptionList(keywordNew = '') {
    // backend search handling
    let targetList

    // add extra parameters
    const targetUrl = searchApi + Object.entries(params || {})
      .map(([key, value]) => key + '=' + value)
      .join('&')

    if (searchApi) {
      try {
        // enable loading
        setLoading(true)

        // construct the request
        let request = {
          method: type || 'GET',
          headers: headers || {}
        }

        // set the request body
        if (type === HTTP.POST) {
          request = {
            ...request,
            body: JSON.stringify({
              [searchTerm]: keywordNew === '' ? (defaultTerm || '') : keywordNew,
              ...payloadParams
            })
          }
        }

        // send API request
        const response = await fetch(targetUrl, request)
        const payload = await response.json()
        targetList = readValue(payload, responsePayload || 'data')
      } catch (error) {
        targetList = []
      } finally {
        setLoading(false)
      }
    } else {
      targetList = searchList
    }

    // populate search list
    setList(targetList)
  }

  /**
   * Initialize first load
   */
  async function initData() {
    // fetch initial list if (min is zero)
    if (minSearchChars === 0) {
      await getOptionList()
    }
    updateCheckList()
  }

  /**
   * Register the selected values
   */
  function updateCheckList() {
    setChecklist(
      value
        .map(item => val(item, valueAttribute))
        .reduce((o, selected) => ({ ...o, [selected]: true }), {})
    )
  }

  // --------------- auto effects -------------- //

  useEffect(() => { initData() }, [])
  useEffect(updateCheckList, [value, valueAttribute])

  return (
    <div className={[className, styles.searchbox__container].join(' ')}>

      {/* Search label */}
      <label className={styles.searchbox__label}> {label} </label>

      {/* Search input */}
      <div className={styles.searchbox__inputwrapper} ref={handleRef}>
        {loading
          ? <LoadingIcon className={styles.searchbox__inputloader} />
          : <ArrowIcon className={[
            styles.searchbox__arrow,
            focus ? styles['searchbox__arrow--active'] : ''
          ].join(' ')}
          // eslint-disable-next-line react/jsx-closing-bracket-location
          />}
        <input
          ref={input}
          readOnly={!focus}
          value={focus ? keyword : getValue().join(',')}
          onClick={() => updateBody(toggleBox)}
          onInput={(event) => {
            const newValue = event.target.value
            setKeyword(newValue)
            if (newValue.length >= minSearchChars) {
              getOptionList(event.target.value)
            }
          }}
          placeholder={placeholder}
          className={[
            styles.searchbox__element,
            focus ? styles['searchbox__element--focused'] : '',
            error ? styles['searchbox__element--error'] : ''
          ].join(' ')}
        />
      </div>

      {/* Search results */}
      <div ref={container} className={styles.searchbox__body}>

        {/* Popup body */}
        <Floatable
          vertical
          {...floatConfigs}
          className={[
            styles.searchbox__content,
            error ? styles['searchbox__content--error'] : ''
          ].join(' ')}
        >

          {(list && list.length > 0)

            // in case of option list
            ? <Scrollbar disableXScroll height={200} contentClass={styles.searchbox__scroll}>

              <ul className={styles.searchbox__checklist}>
                {!selectSingle && <li>
                  <Checkbox
                    hideBox={selectSingle}
                    value={getValue().length !== 0}
                    indeterminate={list.length !== getValue().length}
                    onChange={masterCheck}
                    className={[
                      styles.searchbox__checkbox,
                      getValue().length !== 0 ? styles['searchbox__checkbox--active'] : ''
                    ].join(' ')}
                  >
                    Select All
                  </Checkbox>
                </li>}
                {getFilteredList().map((item, i) =>
                  <li key={i}>
                    <Checkbox
                      hideBox={selectSingle}
                      value={checkBoxValue(item)}
                      onChange={(newValue) => handleCheck(item, newValue)}
                      className={[
                        styles.searchbox__checkbox,
                        checkBoxValue(item) ? styles['searchbox__checkbox--active'] : ''
                      ].join(' ')}
                    >
                      {val(item, labelAttribute)}
                    </Checkbox>
                  </li>
                )}
              </ul>

              {/* eslint-disable-next-line react/jsx-closing-tag-location */}
            </Scrollbar>

            // in case no options
            : <div
              // eslint-disable-next-line react/jsx-indent-props
              className={[
                styles.searchbox__noresults,
                focus ? styles['searchbox__noresults--active'] : ''
              ].join(' ')}
            // eslint-disable-next-line react/jsx-closing-bracket-location
            >
              {loading
                ? <Fragment>
                  <span>
                    <LoadingIcon />
                  </span>
                  <span className={styles.searchbox__loadingtext}>
                    fetching data . . .
                  </span>
                  {/* eslint-disable-next-line react/jsx-closing-tag-location */}
                </Fragment>
                : <span>
                  {/* show message based on state */}
                  {(keyword.length < minSearchChars)
                    ? placeholderSearch || 'Start typing to get results'
                    : noResultsText || 'Search found no results'}
                  {/* eslint-disable-next-line react/jsx-closing-tag-location */}
                </span>}

              {/* eslint-disable-next-line react/jsx-closing-tag-location */}
            </div>}

        </Floatable>

      </div>

      {/* Validation Message */}
      <span
        className={[
          styles.searchbox__error,
          error ? styles['searchbox__error--active'] : ''
        ].join(' ')}
      >
        {errorMessage}
      </span>

    </div>
  )
}

/* --------------- props --------------- */
SearchBox.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderSearch: PropTypes.string,
  noResultsText: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  fullObject: PropTypes.bool,
  minSearchChars: PropTypes.number,
  value: PropTypes.array,
  selectSingle: PropTypes.bool,
  apiConfig: PropTypes.shape({
    searchApi: PropTypes.string,
    type: PropTypes.string,
    headers: PropTypes.object,
    searchTerm: PropTypes.string,
    defaultTerm: PropTypes.string,
    responsePayload: PropTypes.string,
    payloadParams: PropTypes.object,
    params: PropTypes.object
  }),
  searchList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.string)
  ]),
  valueAttribute: PropTypes.string,
  labelAttribute: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  errorMessage: PropTypes.string
}

SearchBox.defaultProps = {
  className: '',
  placeholder: 'Type to search ...',
  minSearchChars: 0,
  value: [],
  searchList: [],
  apiConfig: {}
}

export default SearchBox
