import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { PageDownIcon, PageUpIcon } from './assets/images'
import styles from './assets/Pagination.module.scss'

const Pagination = ({
  className,
  current,
  pageSize,
  total,
  onChange,
  disabled
}) => {
  /**
   * Get the last page number
   */
  const getLastPage = useCallback(function () {
    return Math.ceil(total / pageSize)
  }, [pageSize, total])

  /**
   * Pagination bar algorithm
   */
  const pagination = useCallback(function () {
    // initialize
    const delta = 1
    const range = []
    const activeRange = []
    const last = getLastPage()
    let l

    // logic
    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last ||
        (i >= current - delta + 1 && i < current + delta + 2)) { range.push(i) }
    }
    for (const i of range) {
      if (l) {
        if (i - l === 2) { activeRange.push(l + 1) } else if (i - l !== 1) { activeRange.push(null) }
      }
      activeRange.push(i)
      l = i
    }
    return activeRange.map(value => value != null ? value : null)
  }, [current, getLastPage])

  /**
   * Emit new page value
   * @param {number} page the target page
   */
  function emitValue(page) {
    if (onChange) {
      onChange(page)
    }
  }

  /**
   * Move one page
   * @param {number} step [forward +1 / backward -1]
   */
  function stepPage(step) {
    const newValue = current + step
    if (newValue !== 0 && newValue <= getLastPage()) {
      emitValue(newValue)
    }
  }

  return (
    <div className={[className, styles.pagination__container].join(' ')}>

      {/* Loader */}
      {disabled && <div className={styles.pagination__overlay} />}

      {/* Back */}
      <div
        onClick={() => stepPage(-1)}
        className={[
          styles.pagination__element, styles.pagination__arrow,
          current === 1 ? styles['pagination__arrow--disabled'] : ''
        ].join(' ')}
      >
        <PageDownIcon />
      </div>

      {/* Pages */}
      {pagination().map((item, i) => (
        <div
          key={i}
          onClick={() => item && emitValue(item)}
          className={[
            styles.pagination__element,
            item === current ? styles['pagination__element--active'] : ''
          ].join(' ')}
        >
          <span> {item || '...'} </span>
        </div>
      ))}

      {/* Forward */}
      <div
        onClick={() => stepPage(1)}
        className={[
          styles.pagination__element, styles.pagination__arrow,
          current === getLastPage() ? styles['pagination__arrow--disabled'] : ''
        ].join(' ')}
      >
        <PageUpIcon />
      </div>

    </div>
  )
}

/* --------------- props --------------- */
Pagination.propTypes = {
  className: PropTypes.string,
  current: PropTypes.number,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

Pagination.defaultProps = {
  className: '',
  pageSize: 1,
  disabled: false
}

export default Pagination
