import PropTypes from 'prop-types'

const MenuBody = ({ children }) => children

MenuBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default MenuBody
