
export default function useCommons() {
  /**
   * Get nested value from object
   * @param {object} object the full object with nested props
   * @param {string} path the target value path
   * @returns string
   */
  function readValue(object, path) {
    let targetValue = Object.assign({}, object)
    path.split('.').forEach(x => {
      targetValue = targetValue[x]
    })
    return targetValue
  }

  // return logic
  return {
    readValue
  }
}
