export const FILTER_TYPE = {
  INPUT: 'input',
  SEARCH_BOX: 'searchBox',
  DATE: 'date',
  DATE_RANGE: 'dateRange',
  SELECT: 'select'
}

export const CELL_TYPE = {
  TEXT: 'text',
  IMAGE: 'image'
}
