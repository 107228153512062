import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import useFloat from '../Floatable/hooks/useFloat'
import { CloseIcon } from './assets/images'
import styles from './assets/Modal.module.scss'

const Modal = ({
  children,
  className,
  modalClassName,
  show,
  overlayClose,
  closable,
  onClose
}) => {
  // state
  const {
    active,
    domElement,
    containerRef,
    toggleActive,
    closeOverlay: closeModal
  } = useFloat(closeEffect, true, true)

  // callbacks

  /**
   * Modal opening mechanism
   */
  function toggleModal() {
    if (show) {
      const mmOverlay = document.getElementById('mm_overlay')
      mmOverlay.style.height = '100%'
      mmOverlay.style.zIndex = 10000
    } else {
      closeModal()
    }
    toggleActive(show)
  }

  /**
   * Modal closing mechanism
   */
  function closeEffect() {
    // remove overlay style
    const mmOverlay = document.getElementById('mm_overlay')
    mmOverlay.style = ''

    if (onClose) {
      onClose()
    }
  }

  // --------------- auto effects -------------- //

  useEffect(toggleModal, [show])

  useEffect(() => {
    const escapeClose = (e) => {
      if (active && e.key === 'Escape') {
        closeModal()
      }
    }
    if (window) {
      window.addEventListener('keydown', escapeClose)
    }
    return () => window?.removeEventListener('keydown', escapeClose)
  }, [active])

  const innerContent = () => (
    <div
      ref={containerRef}
      className={[
        className,
        styles.modal__container,
        active ? styles['modal__container--active'] : ''
      ].join(' ')}
    >
      <div
        className={styles.modal__overlay}
        onClick={() => overlayClose && closeModal()}
      >
        <div
          className={[modalClassName, styles.modal__wrapper].join(' ')}
          onClick={(e) => { e.stopPropagation() }}
        >
          {closable && <CloseIcon className={styles.modal__close} onClick={closeModal} />}
          {children}
        </div>
      </div>
    </div>
  )

  // render based on
  if (domElement) {
    return ReactDOM.createPortal(innerContent(), domElement)
  } else {
    return null
  }
}

/* --------------- props --------------- */
Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  modalClassName: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  overlayClose: PropTypes.bool,
  closable: PropTypes.bool
}

Modal.defaultProps = {
  className: '',
  overlayClose: true,
  closable: false
}

export default Modal
