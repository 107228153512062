import PropTypes from 'prop-types'
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import Button from '../Button/Button'
import Checkbox from '../Checkbox/Checkbox'
import DatePicker from '../DatePicker/DatePicker'
import DateRange from '../DateRange/DateRange'
import Input from '../Input/Input'
import MenuBody from '../Menu/components/MenuBody/MenuBody'
import MenuHandle from '../Menu/components/MenuHandle/MenuHandle'
import MenuItem from '../Menu/components/MenuItem/MenuItem'
import Menu from '../Menu/Menu'
import Pagination from '../Pagination/Pagination'
import Scrollbar from '../Scrollbar/Scrollbar'
import SearchBox from '../SearchBox/SearchBox'
import Option from '../Select/components/Option/Option'
import Select from '../Select/Select'
import styles from './assets/DataGrid.module.scss'
import { ArrowDownIcon, CloseIcon, FilterIcon, LoadingIcon, SortingIcon } from './assets/images'
import Row from './components/Row/Row'
import { FILTER_TYPE } from './utils/constants'

const DataGrid = ({
  className,
  label,
  actions,
  pageSize,
  onPageSizeChange,
  page,
  onPageChange,
  filterOptions,
  bulkOptions,
  enableSort,
  data,
  columns,
  noPagination,
  noCountInfo,
  totalCount,
  loading,
  disableScroll
}) => {
  // initialize
  const { rowKey, checkable, bulkActions, onCheckedChange } = bulkOptions
  const { initialFilters, filters, filterBadgeLabel, onFilterChange } = filterOptions
  const [checklist, setChecklist] = useState()
  const [showFilters, setShowFilters] = useState(false)
  const [filterDetails, setFilterDetails] = useState(initialFilters || {})
  const [tempFilters, setTempFilters] = useState(initialFilters || {})
  const [size, setSize] = useState(10)

  // refs
  const noResults = useRef()

  // computed
  const enableFilter = filters.length > 0
  const availableSizes = [10, 20, 50, 100, 300]

  // ----------------- checkbox ---------------- //

  /**
   * Emit the new value
   * @param {object} targetValue
   */
  function emitChange(targetValue) {
    setChecklist(targetValue)
    if (onCheckedChange) {
      onCheckedChange(Object.keys(targetValue))
    }
  }

  /**
   * Toggle all the list checks
   * @param {boolean} value the new value of master
   */
  function masterCheck(value) {
    // determine target value
    let targetValue
    if (value) {
      targetValue = data.map(item => item[rowKey])
        .reduce((o, key) => ({ ...o, [key]: true }), {})
    } else {
      targetValue = {}
    }

    emitChange(targetValue)
  }

  /**
   * Check / uncheck option
   * @param {string|number} rowHandle the selected key
   * @param {boolean} value the new value
   */
  function handleCheck(rowHandle, value) {
    // fetch value
    let targetValue

    if (value) {
      targetValue = {
        ...checklist,
        [rowHandle]: value
      }
    } else {
      const { [rowHandle]: removed, ...remainingChecks } = checklist
      targetValue = remainingChecks
    }

    emitChange(targetValue)
  }

  // ------------------ values ----------------- //

  /**
   * Get the component value selection
   */
  const getValue = useCallback(function () {
    return Object.keys(checklist || {})
  }, [checklist])

  /**
   * Get the checkbox value of element
   * @param {string} rowHandle the row id
   */
  function checkBoxValue(rowHandle) {
    return checklist ? checklist[rowHandle] : false
  }

  // ----------------- updates ----------------- //

  /**
   * Toggle filters box
   */
  function toggleFilters() {
    if (!showFilters) {
      setTempFilters(filterDetails)
    }
    setShowFilters(!showFilters)
  }

  /**
   * Change the filtered object data
   * @param {object} filters new filters value
   */
  function changeFilters(filters) {
    setFilterDetails(filters)

    // call callback if exist
    if (onFilterChange) {
      onFilterChange(filters)
    }
  }

  // remove certain key from filters
  function removeFilterElement(code) {
    const { [code]: value, ...remainingFilters } = filterDetails
    changeFilters(remainingFilters)
  }

  /**
   * Handle input filter change
   * @param {*} event javascript input event
   * @param {string} code the filter code
   */
  function handleInputChange(event, code) {
    const value = event.target.value
    handleGenericChange(value, code, value !== '')
  }

  /**
   * Handle search box filter change
   * @param {*} value array of selected options
   * @param {string} code the filter code
   */
  function handleSearchChange(value, code) {
    handleGenericChange(value, code, value.length !== 0)
  }

  /**
   * Handle object filter change
   * @param {*} value array of selected options
   * @param {string} code the filter code
   * @param {boolean} condition add or remove
   */
  function handleGenericChange(value, code, condition) {
    if (condition) {
      setTempFilters({
        ...tempFilters,
        [code]: value
      })
    } else {
      const { [code]: anyValue, ...remainingFilters } = tempFilters
      setTempFilters(remainingFilters)
    }
  }

  // ------------------ values ----------------- //

  /**
   * Print the value as a string
   * @param {*} value any filter value
   * @param {*} handler change the filter text
   */
  function printValue(value, handler) {
    if (handler && typeof handler === 'function') {
      return handler(value)
    }
    if (Array.isArray(value)) {
      return value.join(',')
    } else if (typeof value === 'object') {
      const stringfied = JSON.stringify(value)
      return stringfied.replaceAll(/{|}|"/g, '')
    } else {
      return value
    }
  }

  // round the size
  useEffect(() => {
    const newSize = availableSizes.find(x => x >= pageSize)
    setSize(newSize || 300)
  }, [pageSize])

  return (
    <div className={[className, styles.datagrid__wrapper].join(' ')}>

      {/* Action and filters */}
      <div className={styles.datagrid__header}>
        <h1 className={styles.datagrid__title}>
          {label}
          {!noCountInfo && <span className={styles.datagrid__count}>
            {totalCount} Items
          </span>}
        </h1>
        <div className={styles.datagrid__controls}>

          {/* Custom user buttons */}
          {actions.map(({ label, onClick }, index) =>
            <Button
              inverted
              key={index}
              onClick={onClick}
            >
              {label}
            </Button>
          )}
          {enableFilter &&
            <Button onClick={toggleFilters}>
              <FilterIcon className={styles.datagrid__filtericon} /> Filter
            </Button>}
        </div>
      </div>

      {/* Top controls */}
      {enableFilter && (
        <Fragment>

          {/* Filters */}
          <div className={[
            styles.datagrid__filters,
            showFilters ? styles['datagrid__filters--active'] : ''
          ].join(' ')}
          >
            <div className='row'>
              {
                filters.map(({ type, code, label, options }, index) => {
                  if (type === FILTER_TYPE.INPUT) {
                    return (
                      <Input
                        key={index}
                        label={label || code}
                        className='col-12 col-sm-6 col-md-4 col-lg-3'
                        placeholder={`Type ${code}`}
                        onInput={(event) => handleInputChange(event, code)}
                        onChange={() => { }}
                        value={tempFilters[code] || ''}
                      />
                    )
                  } else if (type === FILTER_TYPE.SEARCH_BOX) {
                    return (
                      <SearchBox
                        key={index}
                        {...options}
                        label={label || code}
                        className='col-12 col-sm-6 col-md-4 col-lg-3'
                        onChange={(value) => handleSearchChange(value, code)}
                        value={tempFilters[code] || []}
                      />
                    )
                  } else if (type === FILTER_TYPE.DATE) {
                    return (
                      <DatePicker
                        key={index}
                        {...options}
                        label={label || code}
                        className='col-12 col-sm-6 col-md-4 col-lg-3'
                        onChange={(value) => handleGenericChange(value, code, value)}
                        value={tempFilters[code]}
                      />
                    )
                  } else if (type === FILTER_TYPE.DATE_RANGE) {
                    return (
                      <DateRange
                        key={index}
                        {...options}
                        label={label || code}
                        className='col-12 col-sm-6 col-md-4 col-lg-3'
                        onChange={(value) => handleGenericChange(value, code, value)}
                        value={tempFilters[code]}
                      />
                    )
                  } else if (type === FILTER_TYPE.SELECT) {
                    const { list, ...rest } = options
                    return (
                      <Select
                        key={index}
                        {...rest}
                        label={label || code}
                        className='col-12 col-sm-6 col-md-4 col-lg-3'
                        onChange={(value) => handleGenericChange(value, code, value)}
                        value={tempFilters[code]}
                      >
                        {list.map(({ value, label }, i) =>
                          <Option key={i} value={value}> {label} </Option>)}
                      </Select>
                    )
                  } else {
                    return null
                  }
                })
              }
              <div className={styles.datagrid__filterbuttons}>
                <Button
                  inverted onClick={() => {
                    changeFilters({})
                    setTempFilters({})
                  }}
                >
                  Clear
                </Button>
                <Button
                  onClick={() => {
                    changeFilters(tempFilters)
                    toggleFilters()
                  }}
                >
                  Apply Filter
                </Button>
              </div>
            </div>
          </div>

          {/* Active filters */}
          {!showFilters && (Object.entries(filterDetails).length > 0) &&
            <div className={styles.datagrid__activefilters}>

              <span className={styles.datagrid__filterlabel}>
                Applied Filter
              </span>

              {Object.entries(filterDetails).map(([key, value], index) =>
                <div key={index} className={styles.datagrid__filtertag}>
                  <div className={styles.datagrid__filtertagtext}>
                    <span> {key}: </span>
                    <span> {printValue(value, (filterBadgeLabel || {})[key])} </span>
                  </div>
                  <CloseIcon
                    className={styles.datagrid__filtertagclose}
                    onClick={() => removeFilterElement(key)}
                  />
                </div>
              )}

              <span
                className={styles.datagrid__filterclear}
                onClick={() => changeFilters({})}
              >
                Clear All
              </span>
            </div>}
        </Fragment>
      )}

      {/* Bulk actions */}
      {getValue().length > 0 &&
        <div className={styles.datagrid__bulk}>
          <span>
            {data.length === getValue().length ? 'All ' : ' '}
            {getValue().length} items on this page are selected.
          </span>
          <div>
            <Menu className={styles.datagrid__bulkactions}>
              <MenuHandle>
                <div>
                  <span> Actions </span>
                  <ArrowDownIcon />
                </div>
              </MenuHandle>
              <MenuBody>
                {(bulkActions || []).map(({ label: bulkLabel, callback: bulkCallback }, k) => (
                  <MenuItem
                    key={k}
                    onClick={bulkCallback}
                  >
                    {bulkLabel}
                  </MenuItem>
                ))}
              </MenuBody>
            </Menu>
          </div>
        </div>}

      {/* Table */}
      <div
        className={[
          styles.datagrid__container,
          data.length === 0 ? styles['datagrid__container--empty'] : ''
        ].join(' ')}
      >

        {loading &&
          <div className={styles.datagrid__loading}>
            <LoadingIcon />
            <span> loading . . . </span>
          </div>}

        <Scrollbar
          disabled={disableScroll}
          contentClass={styles.datagrid__scroll}
        >
          <table className={styles.datagrid__table}>

            <thead>
              <tr>
                {checkable &&
                  <th className={styles.datagrid__check}>
                    <Checkbox
                      value={getValue().length !== 0}
                      indeterminate={data.length !== getValue().length}
                      onChange={masterCheck}
                      disabled={totalCount === 0}
                    />
                  </th>}
                {columns.map(({ name, sortable }, n) => (
                  <th key={n}>
                    {name}
                    {sortable && <SortingIcon style={{ margin: '0 4px' }} />}
                  </th>
                ))}
              </tr>
            </thead>

            {/* Data */}
            {(data.length > 0) &&
              <tbody>
                {data.map((row, indx) => (
                  <Row
                    key={indx}
                    row={row}
                    rowKey={rowKey}
                    columns={columns}
                    checkable={checkable}
                    checkBoxValue={checkBoxValue}
                    handleCheck={handleCheck}
                    getValue={getValue}
                  />
                ))}
              </tbody>}

          </table>

        </Scrollbar>

        {/* No results */}
        {(!loading && data.length === 0) &&
          <div
            ref={noResults}
            className={styles.datagrid__noresults}
          >
            <div> No data found </div>
          </div>}

        {/* Datagrid Controls */}
        {(!noPagination && data.length > 0) &&
          <div className={styles.datagrid__info}>
            <div className={styles.datagrid__meta}>
              <span>Show</span>
              <Select
                overflowed
                noDefault
                value={size}
                onChange={(val) => {
                  setSize(val)
                  onPageSizeChange(val)
                  onPageChange(1)
                }}
                bodyClassName={styles.datagrid__pagesize}
              >
                {availableSizes.map((item, i) =>
                  <Option key={i} value={item}> {item} </Option>)}
              </Select>
              <span> of {totalCount} items </span>
            </div>
            <div className={styles.datagrid__pagination}>
              <Pagination
                total={totalCount}
                pageSize={size}
                current={page}
                onChange={onPageChange}
              />
            </div>
          </div>}

      </div>

    </div>
  )
}

/* --------------- props --------------- */
DataGrid.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  ),
  pageSize: PropTypes.number,
  onPageSizeChange: PropTypes.func,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  enableSort: PropTypes.bool,
  noPagination: PropTypes.bool,
  noCountInfo: PropTypes.bool,
  loading: PropTypes.bool,
  disableScroll: PropTypes.bool,

  // mapping
  data: PropTypes.arrayOf(
    PropTypes.object
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      render: PropTypes.func,
      sortable: PropTypes.bool,
      type: PropTypes.string,
      className: PropTypes.string
    })
  ),

  // bulk options
  bulkOptions: PropTypes.shape({
    rowKey: PropTypes.string,
    checkable: PropTypes.bool,
    bulkActions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        callback: PropTypes.func
      })
    ).isRequired,
    onCheckedChange: PropTypes.func
  }),

  // filter props
  filterOptions: PropTypes.shape({
    initialFilters: PropTypes.object,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        type: PropTypes.oneOf(
          Object.values(FILTER_TYPE)
        ).isRequired,
        label: PropTypes.string,
        options: PropTypes.object
      })
    ).isRequired,
    onFilterChange: PropTypes.func,
    filterBadgeLabel: PropTypes.object
  })
}

DataGrid.defaultProps = {
  className: '',
  actions: [],
  totalCount: 0,
  pageSize: 10,
  page: 1,
  checkable: false,
  onPageSizeChange: () => { },
  onPageChange: () => { },
  enableSort: false,
  noPagination: false,
  noCountInfo: false,
  data: [],
  columns: [],
  bulkOptions: {
    checked: [],
    checkable: false,
    bulkActions: [],
    onCheckedChange: () => { }
  },
  filterOptions: {
    filters: [],
    filterBadgeLabel: {}
  }
}

export default DataGrid
