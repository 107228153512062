import PropTypes from 'prop-types'
import React, { forwardRef, useEffect, useState } from 'react'
import styles from './assets/Input.module.scss'

const Input = forwardRef(

  ({
    type,
    label,
    labelExtra,
    placeholder,
    error,
    errorMessage,
    className,
    required,
    ...props
  }, ref) => {
    // states
    const [message, setMessage] = useState('')

    useEffect(() => {
      // extract active message
      if (typeof errorMessage === 'object' && errorMessage !== null) {
        const [firstMessage] = Object
          .entries(errorMessage)
          .map(([key, value]) => value && key)
          .filter(x => x)
        setMessage(firstMessage)
      } else {
        setMessage(errorMessage)
      }
    }, [errorMessage])

    return (
      <div className={[className, styles.input__container].join(' ')}>

        <label className={styles.input__label}>
          {label} {required && <span> * </span>}
          {labelExtra && labelExtra()}
        </label>
        <input
          {...props}
          ref={ref}
          type={type}
          className={[
            styles.input__element,
            error ? styles['input__element--error'] : ''
          ].join(' ')}
          placeholder={placeholder}
        />

        {/* Validation Message */}
        <span
          className={[
            styles.input__error,
            error ? styles['input__error--active'] : ''
          ].join(' ')}
        >
          {message}
        </span>

      </div>
    )
  }

)

/* --------------- props --------------- */
Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  labelExtra: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  className: PropTypes.string,
  required: PropTypes.bool
}

Input.defaultProps = {
  type: 'text',
  error: false,
  required: false,
  errorMessage: '',
  placeholder: 'Please enter ...'
}

export default Input
