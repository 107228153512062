import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styles from './assets/Checkbox.module.scss'

const Checkbox = ({
  children,
  value,
  radio,
  indeterminate,
  disabled,
  hideBox,
  onChange,
  className,
  ...props
}) => {
  // initialize
  const [checked, setChecked] = useState(value)
  const [semiChecked, setSemiChecked] = useState(indeterminate)

  useEffect(() => setChecked(value), [value])
  useEffect(() => setSemiChecked(indeterminate), [indeterminate])

  /**
   *
   * @param {bool} newValue of checkbox
   */
  function handleClick() {
    if (disabled) { return }
    if (onChange) { onChange(!checked) }
    setChecked(!checked)
  }

  return (
    <div className={[className, styles.checkbox__container].join(' ')}>

      {/* Checkboc */}
      {!hideBox && (
        radio ? (
          <svg
            {...props}
            xmlns='http://www.w3.org/2000/svg'
            width='19' height='19' viewBox='0 0 19 19'
            className={styles.radio}
            onClick={handleClick}
          >
            <circle cx="9.5" cy="9.5" r="9"
              className={[
                styles.radio__outer,
                disabled ? styles['radio__outer--disabled'] : ''
              ].join(' ')} />
            {checked && <circle cx="9.5" cy="9.5" r="4.5"
              className={[
                styles.radio__inner,
                disabled ? styles['radio__inner--disabled'] : ''
              ].join(' ')} />}
          </svg>
        ) : (
          <svg
            {...props}
            xmlns='http://www.w3.org/2000/svg'
            width='18' height='18' viewBox='0 0 18 18'
            className={[
              styles.checkbox,
              checked ? styles['checkbox--checked'] : '',
              disabled ? styles['checkbox--disabled'] : ''
            ].join(' ')}
            onClick={handleClick}
          >
            <rect x='0.75' y='0.75' width='16.5' height='16.5' rx='3.25' />
            {checked && (
              <path d={semiChecked
                ? 'M13 9L5 9'
                : 'M12.25 6L7.44231 12.25L6 10.3769'}
              />
            )}
          </svg>
        )
      )}

      {/* label part */}
      <div
        className={[
          styles.checkbox__label,
          disabled ? styles['checkbox__label--disabled'] : ''
        ].join(' ')}
        onClick={handleClick}
      >
        {children}
      </div>

    </div>
  )
}

/* --------------- props --------------- */
Checkbox.propTypes = {
  value: PropTypes.bool,
  radio: PropTypes.bool,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  hideBox: PropTypes.bool,
  className: PropTypes.string
}

Checkbox.defaultProps = {
  value: false,
  radio: false,
  indeterminate: false,
  disabled: false,
  hideBox: false,
  className: ''
}

export default Checkbox
