import { useEffect, useRef, useState } from 'react'

export default function useFloat(onClose, overflowed, sticky, rightOverride) {
  // states
  const [active, setActive] = useState(false)
  const [domElement, setDomElement] = useState()
  const [configs, setConfigs] = useState({})
  const [savedCallback, setSavedCallback] = useState()
  const [containerRef, setContainerRef] = useState(useRef())

  // refs
  const handleRef = useRef()
  const contentRef = useRef()

  // overrides
  const overrideWidthRef = useRef()
  const overrideHeightRef = useRef()

  // ----------------- updates ----------------- //

  /**
   * Toggle the show flag
   * @param {boolean} activeOverride override the target active value
   */
  function toggleActive(activeOverride) {
    if (typeof activeOverride === 'boolean') {
      setActive(activeOverride)
    } else {
      setActive(!active)
    }
  }

  /**
   * Close the menu [hide body]
   */
  function closeOverlay() {
    if (active) {
      if (onClose) {
        setTimeout(onClose, 0)
      }
      setActive(false)
    }
  }

  /**
   * Create an overlayed dom element
   */
  function createElement() {
    // skip if not overflowed
    if (!overflowed) return

    // create the overlay if not exist
    if (document.getElementById('mm_overlay') == null) {
      const overlay = document.createElement('div')
      overlay.id = 'mm_overlay'
      document.getElementById('root').appendChild(overlay)
    }

    // use the overlay
    const mmOverlay = document.getElementById('mm_overlay')
    if (containerRef.current == null) {
      const layer = document.createElement('div')
      setDomElement(layer)
      mmOverlay.appendChild(layer)
    }
  }

  /**
   * Update select rendering
   * @param {func} callback execute after updates
   */
  function updateBody(callback) {
    // handle update after the adjust
    setSavedCallback(() => callback)

    // destructure data
    let stylesObject = {}
    const { top, height, left, width } = handleRef.current?.getBoundingClientRect() || {}

    // get orientation
    const isTop = (top / window?.innerHeight) > 0.5
    const isRight = typeof rightOverride === 'boolean'
      ? rightOverride
      : (left / window?.innerWidth) > 0.5

    // set co-orinates in case of overflowed
    if (overflowed) {
      const widthElement = overrideWidthRef.current || contentRef.current || {}
      const heightElement = overrideHeightRef.current || contentRef.current || {}
      const bodyWidth = widthElement.clientWidth
      const bodyHeight = heightElement.clientHeight
      const alignSpace = isRight ? (bodyWidth - width) : 0

      // calculate space
      const element = containerRef.current
      const { marginTop, marginBottom } = element.currentStyle || window.getComputedStyle(element)
      const marginSpace = (parseInt(marginTop) || 0) + (parseInt(marginBottom) || 0)

      stylesObject = {
        width: bodyWidth,
        top: isTop ? (top - bodyHeight - marginSpace) : (top + height),
        left: left - alignSpace
      }
    }

    // assemble style object
    setConfigs({
      isTop,
      isRight,
      style: stylesObject
    })
  }

  // --------------- auto effects -------------- //

  useEffect(() => {
    // on item click
    const handleClick = (event) => {
      // handle click outside the menu
      if (!handleRef.current?.contains(event.target) &&
        (!sticky || !containerRef.current?.contains(event.target))) {
        closeOverlay()
      }
    }

    // register event
    if (document) {
      document.addEventListener('click', handleClick)
    }
    return () => document?.removeEventListener('click', handleClick)
  }, [sticky, active])

  useEffect(createElement, [])

  // return logic
  return {
    active,
    domElement,
    configs,
    savedCallback,
    setSavedCallback,
    toggleActive,
    closeOverlay,
    updateBody,
    handleRef,
    containerRef,
    setContainerRef,
    contentRef,
    overrideWidthRef,
    overrideHeightRef
  }
}
