import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import DatePicker from '../DatePicker/DatePicker'
import styles from './assets/DateRange.module.scss'

const DateRange = ({
  label,
  className,
  value,
  format,
  maxInterval,
  onChange
}) => {
  // states
  const [internalValue, setInternalValue] = useState({})
  const [maxToDate, setMaxToDate] = useState()
  /**
   * Update the range value
   * @param {string} key [from / to] types
   * @param {*} newValue the new value
   */
  function upadteValue(key, newValue) {
    const updatedValue = {
      ...internalValue,
      [key]: newValue
    }

    // handle max interval
    if (key === 'from') {
      const tmpMaxDate = new Date(newValue);
      tmpMaxDate.setDate(tmpMaxDate.getDate() + maxInterval)
      setMaxToDate(new Date(tmpMaxDate));
    }

    // update the value
    setInternalValue(updatedValue)
    if (onChange) {
      onChange(updatedValue)
    }
  }

  useEffect(() => {
    if (value && typeof value === 'object') {
      setInternalValue(value)
    } else {
      setInternalValue({})
    }
  }, [value])

  return (
    <div className={[className, styles.daterange__container].join(' ')}>
      <label className={styles.daterange__label}> {label} </label>

      {/* Date Pickers */}
      <div className={styles.daterange__controls}>
        <span className={styles.daterange__from}>
          <DatePicker
            format={format}
            placeholder='From'
            value={internalValue.from}
            onChange={(value) => upadteValue('from', value)}
          />
        </span>
        <span className={styles.daterange__to}>
          <DatePicker
            right
            format={format}
            placeholder='To'
            value={internalValue.to}
            minDate={internalValue.from}
            maxDate={maxToDate}
            onChange={(value) => upadteValue('to', value)}
          />
        </span>
      </div>

    </div>
  )
}

/* --------------- props --------------- */
DateRange.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.object,
  format: PropTypes.string,
  onChange: PropTypes.func,
  maxInterval: PropTypes.number,
}

DateRange.defaultProps = {
  className: ''
}

export default DateRange
