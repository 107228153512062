import PropTypes from 'prop-types'

const MenuHandle = ({ children }) => children

MenuHandle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default MenuHandle
