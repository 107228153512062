import PropTypes from 'prop-types'
import React, { memo, useCallback } from 'react'
import Checkbox from '../../../Checkbox/Checkbox'
import { CELL_TYPE } from '../../utils/constants'
import styles from './assets/Row.module.scss'

const Row = ({
  row,
  rowKey,
  columns,
  checkable,
  checkBoxValue,
  handleCheck,
  getValue,
  ...props
}) => {
  /**
   * Get the rendered cell
   * @param {*} value the value corresponding to the key
   * @param {*} render the rendering function if available
   */
  const getCellValue = useCallback((value, render) => {
    if (render) {
      return render({
        value: value,
        item: row,
        bulkEnabled: getValue().length > 0
      })
    } else {
      return value
    }
  })

  return (
    <tr {...props}>
      {checkable &&
        <td className={styles.datagrid__check}>
          <Checkbox
            value={checkBoxValue(row[rowKey])}
            onChange={(newValue) => handleCheck(row[rowKey], newValue)}
          />
        </td>}
      {columns.map(({ name, key, render, sortable, type, className, ...args }, n) => (
        <td {...args} data-label={name} key={n} className={className}>
          <div className={styles.datagrid__cell}>
            {type === CELL_TYPE.IMAGE
              ? <img alt='product' className={styles.datagrid__cellimage} src={render ? render(row[key]) : row[key]} />
              : <span className={styles.datagrid__cellcontent}> {getCellValue(row[key], render)} </span>}
          </div>
        </td>
      ))}
    </tr>
  )
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
  rowKey: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      render: PropTypes.func,
      sortable: PropTypes.bool
    })
  ).isRequired,
  checkable: PropTypes.bool,
  checkBoxValue: PropTypes.func,
  handleCheck: PropTypes.func,
  getValue: PropTypes.func
}

export default memo(Row)
