import PropTypes from 'prop-types'
import React from 'react'
import styles from './assets/CardBody.module.scss'

const CardBody = ({ children }) => {
  return (
    <div className={styles.card__body}>
      {children}
    </div>
  )
}

CardBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default CardBody
