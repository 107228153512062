import PropTypes from 'prop-types'
import React, { memo, useEffect, useRef, useState } from 'react'
import styles from './assets/Scrollbar.module.scss'
import useScroll from './hooks/useScroll'
import { MODE } from './utils/constants'

const Scrollbar = ({
  children,
  height,
  className,
  contentClass,
  onScroll,
  disabled,
  disableYScroll,
  disableXScroll,
  wide,
  ...props
}) => {
  // states
  const [update, setUpdate] = useState(false)
  useEffect(() => setUpdate(!update), [])

  // refs
  const wrapper = useRef()
  const content = useRef()

  // scroll hooks
  const {
    upFunction: mouseUpY,
    moveFunction: mouseMoveY,
    adjustBarPosition: adjustBarY
  } = useScroll(wrapper.current, content.current, MODE.VERTICAL)
  const {
    upFunction: mouseUpX,
    moveFunction: mouseMoveX,
    adjustBarPosition: adjustBarX
  } = useScroll(wrapper.current, content.current, MODE.HORIZONTAL)

  /**
   * Handle scroll callback
   * @param {*} e event of scroll
   */
  function scrollAction(e) {
    setUpdate(!update)
    if (onScroll) {
      onScroll(e)
    }
  }

  // calculate new values
  const { showHandle: handleY, barSize: sizeY, barOffset: offsetY } = adjustBarY()
  const { showHandle: handleX, barSize: sizeX, barOffset: offsetX } = adjustBarX()

  return (
    <div
      {...props}
      className={[className, styles.scrollbar__container].join(' ')}
    >
      <div className={styles.scrollbar__adjust}>
        <div
          ref={wrapper}
          onScroll={scrollAction}
          className={[
            styles.scrollbar__limiter,
            disabled ? styles['scrollbar__limiter--disabled'] : '',
            disableYScroll ? styles['scrollbar__limiter--disabledy'] : '',
            disableXScroll ? styles['scrollbar__limiter--disabledx'] : ''
          ].join(' ')}
          style={{ maxHeight: height }}
        >
          <div
            ref={content}
            className={[contentClass, styles.scrollbar__content].join(' ')}
          >
            {children}
          </div>
        </div>
      </div>

      {/* Handle section */}
      {!disabled && !disableYScroll && handleY &&
        <div
          className={[
            styles.scrollbar__track,
            styles.scrollbar__tracky,
            wide ? styles['scrollbar__tracky--wide'] : ''
          ].join(' ')}
        >
          <div
            className={styles.scrollbar__handle}
            style={{
              top: offsetY + '%',
              height: sizeY + '%'
            }}
          >
            <span
              className={styles.scrollbar__bar}
              onMouseDown={() => {
                document.addEventListener('mouseup', mouseUpY)
                document.addEventListener('mousemove', mouseMoveY)
              }}
            />
          </div>
        </div>}
      {!disabled && !disableXScroll && handleX &&
        <div
          className={[
            styles.scrollbar__track,
            styles.scrollbar__trackx,
            wide ? styles['scrollbar__trackx--wide'] : ''
          ].join(' ')}
        >
          <div
            className={styles.scrollbar__handle}
            style={{
              left: offsetX + '%',
              width: sizeX + '%'
            }}
          >
            <span
              className={styles.scrollbar__bar}
              onMouseDown={() => {
                document.addEventListener('mouseup', mouseUpX)
                document.addEventListener('mousemove', mouseMoveX)
              }}
            />
          </div>
        </div>}
    </div>
  )
}

/* --------------- props --------------- */
Scrollbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  height: PropTypes.number,
  contentClass: PropTypes.string,
  onScroll: PropTypes.func,
  disabled: PropTypes.bool,
  disableYScroll: PropTypes.bool,
  disableXScroll: PropTypes.bool,
  wide: PropTypes.bool
}

Scrollbar.defaultProps = {
  contentClass: ''
}

export default memo(Scrollbar)
